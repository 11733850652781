// import '../../config/node_modules/regenerator-runtime/runtime';
import('./scripts/scrollContainer');
// import './scripts/functions';
// import './scripts/lazyload';
// import './scripts/updatecss';
// import { itemsSlider, reviewSlider } from './scripts/swiper';
// itemsSlider();
// reviewSlider();

import './styles/style';


